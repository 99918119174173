var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')}},[_c('template',{slot:"links"},[(_vm.permisos.cartera_ini)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.cartera'),
          icon: 'tim-icons icon-paper',
          path: '/cartera'
        }}}):_vm._e(),(_vm.permisos.cartera_fin)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.generica'),
          icon: 'tim-icons icon-notes',
          path: '/generica'
        }}}):_vm._e(),(_vm.permisos.admin)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.validacion'),
          icon: 'tim-icons icon-book-bookmark',
          path: '/validacion'
        }}}):_vm._e(),(_vm.permisos.admin)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.cierre'),
          icon: 'tim-icons icon-coins',
          path: '/cierre'
        }}}):_vm._e(),(_vm.permisos.report_Direc)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.reporte'),
          icon: 'tim-icons icon-chart-bar-32',
          path: '/reporte'
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.reporteD'),
          icon: 'tim-icons icon-wallet-43',
          path: '/directivo'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.historial'),
          icon: 'tim-icons icon-calendar-60',
          path: '/historial'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.admin'),
          icon: 'tim-icons icon-single-02',
          path: '/usuarios'
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.usuarios'),
          icon: 'tim-icons icon-tablet-2',
          path: '/us_historial'
        }}}),(_vm.permisos.historial)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.empleados'),
          icon: 'tim-icons icon-puzzle-10',
          path: '/empleados'
        }}}):_vm._e(),(_vm.permisos.historial)?_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.maps'),
          icon: 'tim-icons icon-square-pin',
          path: '/mapa'
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.call'),
          icon: 'tim-icons icon-square-pin',
          path: '/call'
        }}}),_c('sidebar-item',{staticStyle:{"min-height":"100px"}})],1)],2),_c('sidebar-share',{attrs:{"background-color":_vm.sidebarBackground},on:{"update:backgroundColor":function($event){_vm.sidebarBackground=$event},"update:background-color":function($event){_vm.sidebarBackground=$event}}}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }